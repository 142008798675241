@font-face {
  font-family: 'gardens';
  src: url(./fonts/GardensC_03_1.otf);
}

body {
  margin: 0;
  font-family: "Helvetica Neue", "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #0D0A0B;
  background:#ffffff;
  text-align: center;
  margin-top:150px;
  padding:20px
}

h3{
  margin:20px 0 3px 0;
  padding:0 0px;
  color:#484D6D;
  text-transform: uppercase;
  display: inline-block;
  border-radius: 3px;
  font-size:0.9em
}

input{
  border:none;
  border-radius: 7px;
  padding: 10px 20px;
  font-size: 30px;
  background:#4CB944;
  color:white;
  box-sizing: border-box;
  width:100%;
  max-width: 350px;
}
input::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: rgba(255,255,255, .5);
  font-style: italic;
}

textarea:focus, input:focus{
  outline: none;
}

h1{
  font-size:55px;
  color:#ff6347;
  font-family:gardens;
  margin:30px
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
